$(document).ready(function ($) {
    /* //Esse função será usada posteriormente para verificar
      // se a image tem um tamanho mínimo para compartilhamento.
    
    function isValidImage() {
        	var tmpImg = new Image();
            tmpImg.src= $("meta[property='og:image']").attr("content");
            $(tmpImg).one('load', function() {
                if(tmpImg.width < 200 ||tmpImg.height < 200) {
                   $("meta[property='og:image']").attr("content", 'www.'+window.location.hostname+'/images/grupo-npo-logo-vertical.png');
                }
            });
    	}
    	isValidImage();*/

    $("#share").jsSocials({
        showLabel: false,
        showCount: false,

        shares: [{
            renderer: function () {
                var $result = $("<div>");

                var script = document.createElement("script");
                script.text = "(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = \"//connect.facebook.net/en_EN/sdk.js#xfbml=1&version=v2.3\"; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'facebook-jssdk'));";
                $result.append(script);

                $("<div>").addClass("fb-share-button")
                    .attr("data-layout", "button_count")
                    .appendTo($result);

                return $result;
            }
        }, {
            renderer: function () {
                var $result = $("<div>");

                var script = document.createElement("script");
                script.src = "https://apis.google.com/js/platform.js";
                $result.append(script);

                $("<div>").addClass("g-plus")
                    .attr({
                        "data-action": "share",
                        "data-annotation": "bubble"
                    })
                    .appendTo($result);

                return $result;
            }
        }, {
            renderer: function () {
                var $result = $("<div>");

                var script = document.createElement("script");
                script.src = "//platform.linkedin.com/in.js";
                $result.append(script);

                $("<script>").attr({
                        type: "IN/Share",
                        "data-counter": "right"
                    })
                    .appendTo($result);

                return $result;
            }
        }, {
            renderer: function () {
                var $result = $("<div>");

                var script = document.createElement("script");
                script.text = "window.twttr=(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],t=window.twttr||{};if(d.getElementById(id))return t;js=d.createElement(s);js.id=id;js.src=\"https://platform.twitter.com/widgets.js\";fjs.parentNode.insertBefore(js,fjs);t._e=[];t.ready=function(f){t._e.push(f);};return t;}(document,\"script\",\"twitter-wjs\"));";
                $result.append(script);

                $("<a>").addClass("twitter-share-button")
                    .text("Tweet")
                    .attr("href", "https://twitter.com/share")
                    .appendTo($result);

                return $result;
            }
        }]
    });

    $("#share-whatsapp").jsSocials({
        shares: ["whatsapp"],
        css: "share-whatsapp"
    });

    function bs_input_file() {
        $(".input-file").before(
            function () {
                if (!$(this).prev().hasClass('input-ghost')) {
                    var element = $("<input type='file' id='input-ghost' class='input-ghost' style='visibility:hidden; height:0; position:absolute' accept='.doc,.pdf,.docx'>");

                    element.attr("name", $(this).attr("name"));

                    element.change(function () {
                        element.next(element).find('input').val((element.val()).split('\\').pop());
                    });

                    $(this).find("button.btn-choose").click(function () {
                        element.click();
                    });

                    $(this).find("button.btn-reset").click(function () {
                        element.val(null);
                        $(this).parents(".input-file").find('input').val('');
                    });

                    $(this).find('input').css("cursor", "pointer");

                    $(this).find('input').mousedown(function () {
                        $(this).parents('.input-file').prev().click();
                        return false;
                    });

                    return element;
                }
            }
        );
    }

    $(function () {
        bs_input_file();
    });

    $('.formas-de-pagamento input[type="radio"]').change(function () {
        $('.formas-de-pagamento label').removeClass('selected');
        $(this).parent().addClass("selected");
    })

    $('.data').mask('00/00/0000');
    $('.data-card').mask('00/00');
    $('.cpf').mask('000.000.000-00', {
        reverse: true
    });
    $('.card').mask('0000 0000 0000 0000');
    $('.card-security').mask('000');
});